<template>
  <sub-page
      :title="$t('PaymentTypes')"
      icon="mdi-cash"
      :header-action="saveChanges"
      :header-action-text="$t('Save')"
      v-model="$store.state.settings.pageData"
  >


    <v-sheet
        style="border-radius: 4px"
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class="pa-6 my-6 align-center justify-space-between"
        :class="[{'d-flex' : !SM}]"
    >

      <img
          src="https://fondy.ua/uploads/2022/08/fondy_logo.svg"
          height="24px"
          class="wsRoundedHalfTop mr-4"
      >
      <h4 class="font-weight-regular wsACCENT " :class="[{'px-6' : !SM} , {'mt-3' : SM}]">{{ $t('fondy_description') }}</h4>

      <ws-button
          :block="SM"
          href="https://fondy.ua/uk/cms/westudy/?connect=221064"
          new-window
          class="mt-3 mr-2"
          label="Реєстрація"
          icon="mdi-open-in-new"
          :left="!SM"
      />


      </v-sheet>

    <ws-accordion
        :items="[{name : this.$t('Currency') , value : 'currency', icon : 'mdi-cash' , noExpand : true, expanded : false, subtitle : this.$t('CurrencyDescription')}]"
    >
      <template #action.currency>
        <ws-select
            v-model="currency"
            :items="CURRENCY_SELECT"
            width="140px"
            class="mr-6"
        ></ws-select>
      </template>
    </ws-accordion>

    <div class="pt-4 pb-16">
      <template v-for="(block,i) in settingsObject" >

        <v-sheet
            v-if="block.name === 'Online' && (!block.visibilityField || (entityData[block.visibilityField] === block.visibilityFieldCondition))"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            :key="`block${i}`"
            class=" mb-3 wsRoundedLight">




          <!-- ::  PERMISSION ITEM ::  -->
          <template v-for="(item,j) in block.items" >
            <v-expand-transition :key="`item${i}${j}`" >
              <div v-if="!item.visibilityField || (entityData[item.visibilityField] === item.visibilityFieldCondition)" >

              <div>
                <div class="pa-4 py-6 d-flex justify-space-between">
                  <!-- icons and name-->
                  <div class="d-flex">
                    <v-icon size="20" :color="wsATTENTION" class="mr-3">{{ 'mdi-cash-multiple' }}</v-icon>
                    <div>
                      <h4 :style="`color : ${wsDARKER}`" style="font-size: 16px; font-weight: 600"  >{{ item.name  }}</h4>
                      <h5 :style="`color : ${wsACCENT}`" style="font-size: 16px; line-height: 1.8" class="font-weight-regular ">{{  item.description }}</h5>
                    </div>
                  </div>
                  <!-- ::  Selectors and fields ::  -->
                  <div>
                    <div v-if="item.type === 'ftSelect'"  >

                      <ft-select  :items="item.selectItems" v-model="entityData[item.value]" isBoolean class="pointer">
                        <template #default="{text}">
                          <v-chip
                              :color="wsACCENT"
                              :style="!entityData[item.value] ? 'opacity : 0.5' : ''"
                              class="px-1 justify-end font-weight-medium pointer"
                              dark
                          >
                            <v-icon  class="mr-1" >
                              {{ getValueText( item.selectItems,entityData[item.value], true ) || 'mdi-check-circle-outline' }}
                            </v-icon>

                            <h5 class="mx-2">{{ text  }}</h5>

                            <v-icon >mdi-menu-down</v-icon>
                          </v-chip>
                        </template>

                      </ft-select>
                    </div>

                    <liq-pay-settings
                        v-if="item.type === 'online_liqpay'"
                        v-model="entityData[item.value]"
                        @input="onlinePaymentChange($event , 'online_liqpay')" />
                    <mono-settings
                        v-if="item.type === 'online_mono'"
                        v-model="entityData[item.value]"
                        @input="onlinePaymentChange($event , 'online_mono')"/>
                    <wayforpay-settings
                        v-if="item.type === 'online_wayforpay'"
                        v-model="entityData[item.value]"
                        @input="onlinePaymentChange($event , 'online_wayforpay')"/>
                    <fondy-settings
                        v-if="item.type === 'online_fondy'"
                        v-model="entityData[item.value]"
                        @input="onlinePaymentChange($event , 'online_fondy')"/>
                    <stripe-settings
                        v-if="item.type === 'online_stripe'"
                        v-model="entityData[item.value]"
                        @input="onlinePaymentChange($event , 'online_stripe')"/>


                  </div>

                </div>
              </div>
                <v-divider
                    v-if="block.items.length > 1 "
                    :style="`border-color :  ${wsBACKGROUND}`"
                    :key="`item${i}${j}`" />

              </div>
            </v-expand-transition>
          </template>
        </v-sheet>
      </template>
    </div>



  </sub-page>
</template>

<script>
import liqPaySettings from "@/components/pages/businessDashboard/businessSettings/payments/liqPaySettings";
import monoSettings from "@/components/pages/businessDashboard/businessSettings/payments/monoSettings";
import fondySettings from "@/components/pages/businessDashboard/businessSettings/payments/fondySettings";
import wayforpaySettings from "@/components/pages/businessDashboard/businessSettings/payments/wayforpaySettings";
import stripeSettings from "@/components/pages/businessDashboard/businessSettings/payments/stripeSettings";


import {mapActions, mapState} from "vuex";


export default {
  name: "BusinessDashPayments",
  components : {
    liqPaySettings,
    monoSettings,
    fondySettings,
    wayforpaySettings,
    stripeSettings
  },
  data(){
    return {
      currency : 'uah',
      entityData : {
        online_liqpay : false,
      },
      settingsObject : [],

      liqPayNotSet   : false,
      displayLiqpay  : false,
      liqpay : {
        public_key   : null,
        private_key  : null,
      },
      liqPayConfirm  : false,


    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
  },
  methods : {
    ...mapActions('business', [ 'GET_PAYMENT_TYPES','EDIT_PAYMENT_TYPES']),
    ...mapActions('liqpay', ['LINK_BUSINESS', 'UNLINK_BUSINESS' ]),

    onlinePaymentChange( value , client ) {

      if ( value ) {
        let items = Object.keys(this.entityData).filter( el=> el.includes('online_') && el !== client );
        items.forEach(item => {
          this.entityData[item] = false
        })
      }

    },

    async saveChanges(){
      let data = {
        currency : this.currency ,
        payments : []
      };

      Object.keys(this.entityData).forEach((payment)=>{
        if (this.entityData[payment]) {
          data.payments.push(payment)
        }
      })

      if ( await this.EDIT_PAYMENT_TYPES(data) ) {
        this.notify(this.$t('ChangesSaved'))
        this.$store.state.business.selectedBusiness.currency = this.currency
      }

    },

    //technical
    getValueText(items,value, icon = false) {
      if ( !items ) { return '' }
      if ( items.length === 0) { return '' }
      let item = items.find( el=> el.value === value );
      if (!item) { return '' }
      return !icon ? item.text : item.icon
    }
  },
  async mounted(){

    let result = await this.GET_PAYMENT_TYPES()
    if ( !result ) { return }

    this.entityData = JSON.parse(JSON.stringify(result.merge))
    this.settingsObject = result.object ?? []

    this.currency = this.BUSINESS_CURRENCY

  }
}
</script>

<style scoped>

</style>